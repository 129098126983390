<template>
  <div class="parent">
    <div class="top">
      <div class="main">
        <div class="title" @click="toIndex()">
          <p><i class="el-icon-arrow-left"></i> 返回主页</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-form">
        <div style="">
          <div style="text-align: center;font-size: 40px">登陆</div>
          <br>
          <br>
          <br>

          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item style="width: 400px;height: 70px; margin: 0 auto" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>

            <el-form-item style="width: 400px;height:70px;margin: 0 auto" prop="password">
              <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
            </el-form-item>

            <el-form-item >
              <el-button type="primary" @click="login()" :disabled="loginBtnState" style="width: 300px;height: 50px" :loading="loading">立即登陆</el-button>
            </el-form-item>
            <br>
            <el-form-item>
              <span>还没有帐号?</span><a @click="toRegister()">立即注册</a><br>
              <span>忘记密码?</span><a @click="toForgotPwd()">忘记密码</a>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        phone: "",
        password: ""
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {pattern:/^1\d{10}$/, message: '手机号格式有误', trigger: 'blur'},
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      loading: false,
      loginBtnState:false,
    }
  },
  mounted() {
    window.addEventListener('keydown',this.keyDown);
  },
  methods: {
    keyDown(e) {
      if(e.keyCode == 13){
        this.login();
      }
    },
    login: function () {
      this.loginBtnState=true;
      this.loading = true
      this.$axios({
        method: 'post',
        url: '/login/doLogin',
        params: {
          "empPhone": this.form.phone,
          "empPwd": this.form.password
        }
      }).then(response => {
        if (response.data.code === 200) {
          let moduleList=response.data.data.moduleList;
          if (moduleList.length > 0) {
            this.$store.commit("setSidebars", moduleList[0].child)
          }
          this.$store.commit("setRouterView", {moduleList})
          let buttonList = response.data.data.buttonList;
          this.$store.commit("setPermission", buttonList)
          localStorage.setItem("emp",JSON.stringify(response.data.data.emp));
          localStorage.setItem("roles",response.data.data.roles);
          localStorage.setItem("tenantCrop",response.data.data.emp.tenantCrop);
          localStorage.setItem("shops", response.data.data.shops)

          //设置下次过期时间 当前登陆时间+1天
          localStorage.setItem("loginTimeOut",Math.round(new Date().getTime()/1000+60*60*24).toString())

          this.$axios.defaults.headers['token'] = response.data.data.token;
          this.$axios.defaults.headers['isWebView'] = "WebView";
          this.$axios.defaults.headers['empId'] = response.data.data.emp.id;
          this.$axios.defaults.headers['tenantCrop'] = response.data.data.emp.tenantCrop;
          this.$store.commit("addTab", {label: "首页", name: "/home", icon: 'el-icon-s-home'})
          this.$store.commit('setActiveIndex', "/home");
          let self=this;
          setTimeout(function () {
            self.$router.push("/main");
            self.loginBtnState=false;
            self.loading = false;
          }, 700);

          this.$message({
            showClose: true,
            message: '登陆成功',
            type: 'success'
          });
        } else {
          this.loginBtnState=false;
          this.loading = false
          this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          });
        }
      });

    },
    toRegister:function (){
      this.$router.push('/register')
    },
    toForgotPwd:function (){
      this.$router.push('/forgot-password')
    },
    toIndex:function (){
      this.$router.push('/')
    },
  },
  destroyed(){
    window.removeEventListener('keydown',this.keyDown,false);
  }
}
</script>

<style scoped>
/*修改浏览器滚动条开始*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;

}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f6f7;

}

.parent {
  height: 100%
}

.top {
  width: 100%;
  background: #FFFFFF;
  height: 80px;
}

.main {
  width: 1210px;
  margin: 0 auto;
}

.logo {
  width: 80px;
  height: 80px;
  float: left;
  /*border-radius: 50%;*/
}

.title {
  float: left;
  height: 80px;
  cursor:pointer;
}

.title p {
  padding-top: 25%;
  font-size: 22px;
}

.content {
  width: 700px;
  height: 700px;
  background: #FFFFFF;
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 45%; /*偏移*/
  transform: translateY(-50%);
  box-shadow: 0 4px 10px 0 rgba(60, 65, 71, 0.1);
}

.content-form {
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 40%; /*偏移*/
  transform: translateY(-50%);
}

.content form {
  text-align: center;
}

.content h1 {
  font-size: 34px;
}

.content input {
  width: 400px;
  height: 50px;
  display: block;
  margin: 0 auto
}

.content button {
  width: 400px;
  height: 50px;
  display: block;
  margin: 0 auto
}

.content p {
  display: inline-block;
}

.content a {
  color: #6592ec;
  font-weight: bold;
  display: inline-block;
  cursor: pointer ;
}
</style>